import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgIf } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { SuedoArticle } from '../../../interfaces/suedo-article.interface';

export interface QtyModalInputInterface {
  quantity: number;
  article: SuedoArticle | undefined;
}

@Component({
  selector: 'app-qty-modal-input',
  templateUrl: './qty-modal-input.component.html',
  styleUrls: ['./qty-modal-input.component.scss'],
  standalone: true,
  imports: [TranslocoModule, ReactiveFormsModule, FormsModule, NgIf],
})
export class QtyModalInputComponent implements OnInit {
  article!: SuedoArticle;
  quantity!: number;
  decQuantityDown: number = 0;
  decQuantityUp: number = 0;
  decQuantityDefault: number = 1;

  constructor(
    @Inject(MatDialogRef) public dialogRef: MatDialogRef<QtyModalInputComponent>,
    @Inject(MAT_DIALOG_DATA) public data: QtyModalInputInterface,
  ) {}

  mapDataObject(data: QtyModalInputInterface) {
    this.article = data.article!;
    this.quantity = data.quantity;
    this.setData();
  }

  setData() {
    if (this.article.decQuantityPackage <= 0) this.article.decQuantityPackage = 1;

    // rounding down
    //	this.decQuantityDown = this.decQuantity / this._oCartItem.oArticle.decQuantityPackage;
    this.decQuantityDown = Math.floor(this.quantity / this.article.decQuantityPackage);
    this.decQuantityDown = this.decQuantityDown * this.article.decQuantityPackage;

    // rounding up
    //	this.decQuantityUp = this.decQuantity / this._oCartItem.oArticle.decQuantityPackage;
    this.decQuantityUp = Math.ceil(this.quantity / this.article.decQuantityPackage);
    this.decQuantityUp = this.decQuantityUp * this.article.decQuantityPackage;

    this.decQuantityDefault = this.article.decQuantityPackage > 0 ? this.article.decQuantityPackage : 1;
  }

  roundUp() {
    this.dialogRef.close(this.quantity ? this.decQuantityUp : this.decQuantityDefault);
  }

  roundDown() {
    this.dialogRef.close(this.quantity ? this.decQuantityDown : this.decQuantityDefault);
  }

  cancel() {
    this.dialogRef.close(this.decQuantityDefault);
  }

  ngOnInit(): void {
    this.mapDataObject(this.data);
  }
}
